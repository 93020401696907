import React, {Component} from "react";
import {Form} from "react-bootstrap";

type InputAmountProps = {
    onValidInput: (v: string) => void
    value: string
}

class InputAmount extends Component<InputAmountProps> {

    render() {
        return <Form.Group controlId="newExpenseAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control type="text" placeholder="Enter amount" value={this.props.value}
                          onChange={this.handleChange}/>
        </Form.Group>
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value || e.target.value === "") {
            this.props.onValidInput("")
            return;
        }

        let value = Number(e.target.value);
        if (isNaN(value) || value < 0 || !Number.isInteger(value)) {
            return;
        }

        this.props.onValidInput(value.toString())
    }
}

export default InputAmount;