import React, {FC} from "react";
import {Container} from "react-bootstrap";
import {Expense} from "../../api/client";
import {Row} from "react-bootstrap";
import ExpensePanel from "../expense-panel/ExpensePanel";
import {Col} from "react-bootstrap";
import {resolveExpenseType, resolveCreateLink} from "../../utils/Utils";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {ExpensesService} from "../../service/ExpensesService";

type ExpenseGroupProps = {
    type: string
    expenses: Expense[]
    expensesService: ExpensesService
}

const ExpenseGroup: FC<ExpenseGroupProps> = ({type, expenses, expensesService}) => {
    const typeExpenses = expenses.filter(e => e.type === type);
    const history = useHistory();
    return <Container className="mb-4">
        <Row>
            <Col className="text-center">
                <h6>{resolveExpenseType(type) + " expenses"}</h6>
            </Col>
        </Row>
        <Row>
            {typeExpenses.length === 0 ?
                <Col className="text-center m-2 text-muted">
                    No expenses of this type. Add a new one
                </Col>
                :
                <Col>
                    {typeExpenses.map(e => <ExpensePanel key={"expense-panel" + e.id} expense={e} expensesService={expensesService}/>)}
                </Col>
            }
        </Row>
        <Row>
            <Col className="text-center m-2">
                <Button style={{borderColor: "#6c757d57"}} variant="outline-secondary"
                        onClick={() => history.push(resolveCreateLink(type))}>
                    Add new
                </Button>
            </Col>
        </Row>
    </Container>
}

export default ExpenseGroup
