import React, {Component} from "react";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ExpensesService} from "../../service/ExpensesService";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {formatMoment, formatMomentISO, inYear, nextDay, parseMomentISO, prevDay, yearAgo} from "../../utils/Utils";
import {Report} from "../../api/client";
import ReportPanel from "../report-panel/ReportPanel";
import moment, {Moment} from "moment";

type ReportPageProps = RouteComponentProps & { expensesService: ExpensesService }
type ReportPageState = { processing: boolean, report: Report | null, error: boolean,
    startDate: Moment, startAmount: string, endDate: Moment }

class ReportPage extends Component<ReportPageProps, ReportPageState> {
    private today = moment().startOf('day');

    readonly state = {
        processing: false,
        report: null,
        error: false,
        startDate: moment(this.today),
        startAmount: "",
        endDate: nextDay(this.today)
    };

    render() {
        return <Container>
            <Row className="mt-3">
                <Col className="text-center"><h3>Generate report</h3></Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="reportStartDate">
                        <Form.Label>Report start date (inclusive). Min: {formatMoment(this.startMin())}</Form.Label>
                        <Form.Control type="date" placeholder="Report start date (inclusive)"
                                      min={formatMomentISO(this.startMin())}
                                      max={formatMomentISO(this.startMax())}
                                      value={formatMomentISO(this.state.startDate)}
                                      onChange={this.handleStartDateInputChange} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="reportStartAmount">
                        <Form.Label>Balance at the start</Form.Label>
                        <Form.Control type="text" placeholder="Enter balance" value={this.state.startAmount}
                                      onChange={this.handleStartAmountInputChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="reportEndDate">
                        <Form.Label>Report end date (exclusive). Max: {formatMoment(this.endMax())}</Form.Label>
                        <Form.Control type="date" placeholder="Report end date (exclusive)"
                                      min={formatMomentISO(this.endMin())}
                                      max={formatMomentISO(this.endMax())}
                                      value={formatMomentISO(this.state.endDate)}
                                      onChange={this.handleEndDateInputChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button disabled={this.isButtonDisabled()}
                            onClick={this.handleGenerateClicked}>Generate</Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-center">
                    {this.state.processing ? <Spinner animation="border"/> : null}
                    {this.state.report != null && this.state.startAmount != null ?
                        <ReportPanel report={this.state.report!!} startAmount={Number(this.state.startAmount)}
                                     startDate={formatMoment(this.state.startDate)}
                                     endDate={formatMoment(this.state.endDate)}/> : null}
                    {this.state.error ? <Alert variant="danger">An error occurred while working report</Alert> : null}
                </Col>
            </Row>
        </Container>
    }

    private endMin() {
        return nextDay(this.state.startDate);
    }

    private startMax() {
        return prevDay(this.state.endDate);
    }

    handleGenerateClicked = () => {
        this.setState({processing: true, report: null, error: false});

        this.props.expensesService.getReport(formatMomentISO(this.state.startDate), this.state.startAmount, formatMomentISO(this.state.endDate))
            .then(r => this.setState({processing: false, report: r, error: false}))
            .catch(e => {
                this.setState({processing: false, report: null, error: true});
                console.log(e) //LOG
            })
    }

    handleStartDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseMomentISO(e.target.value);
        if (!newValue.isValid() || newValue >= this.state.endDate) {
            return;
        }
        this.setState({startDate: newValue});
    }

    handleEndDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseMomentISO(e.target.value);
        if (!newValue.isValid() || newValue <= this.state.startDate) {
            return;
        }
        this.setState({endDate: parseMomentISO(e.target.value)});
    }

    handleStartAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({startAmount: ""});
            return;
        }

        let value = Number(e.target.value);
        if (isNaN(value) || value < 0 || !Number.isInteger(value)) {
            return;
        }

        this.setState({startAmount: value.toString()});
    }

    isButtonDisabled = () => {
        return this.state.processing || this.state.startAmount === ""
            || this.state.startDate.isBefore(this.startMin()) || this.state.endDate.isAfter(this.endMax())
    }

    private endMax() {
        return inYear(this.state.startDate);
    }

    private startMin() {
        return yearAgo(this.state.endDate);
    }
}

export default withRouter(ReportPage);
