import {EVERY_X_DAYS, MONTHLY, ONE_TIME, OVER_MONTH} from "./Const";
import moment, {Moment} from "moment";

export function resolveExpenseType(type: string) {
    switch (type) {
        case MONTHLY:
            return "Monthly"
        case ONE_TIME:
            return "One-time"
        case OVER_MONTH:
            return "During-month"
        case EVERY_X_DAYS:
            return "Every X days"
        default :
            return ""
    }
}

export function resolveCreateLink(type: string) {
    switch (type) {
        case OVER_MONTH:
            return "create-over-month"
        case ONE_TIME:
            return "create-one-time"
        case MONTHLY:
            return "create-monthly"
        case EVERY_X_DAYS:
            return "create-every-x"
        default :
            return ""
    }
}

export function resolveExpensePanelDesc(type: string, params: string | undefined) {
    switch (type) {
        case OVER_MONTH:
            return "During the month"
        case ONE_TIME:
            return "On " + formatFromISO(params)
        case MONTHLY:
            return "On " + params + " day of the month"
        case EVERY_X_DAYS:
            return "Every " + params?.split(";")[1] + " days starting " + formatFromISO(params?.split(";")[0])
        default :
            return ""
    }
}

export function hasWarning(type: string, params: string | undefined) : boolean {
    if (type === ONE_TIME) {
        if (!params) {
            return false;
        }
        let expenseDate = parseMomentISO(params);
        let currentDate = moment().startOf('day');
        return expenseDate.isBefore(currentDate);
    } else {
        return false;
    }
}

export function formatFromISO(date?: string) {
    if (!date) {
        return ""
    }
    return formatMoment(parseMomentISO(date))
}

export function formatMoment(moment: Moment) {
    return moment.format("DD.MM.YYYY")
}

export function formatMomentISO(moment: Moment) {
    return moment.format("YYYY-MM-DD")
}

export function parseMomentISO(value: string) {
    return moment(value, "YYYY-MM-DD")
}

export function nextDay(m: Moment) {
    return moment(m).add(1, 'days')
}

export function prevDay(m: Moment) {
    return moment(m).subtract(1, 'days')
}

export function yearAgo(m: Moment) {
    return moment(m).subtract(365, 'days')
}

export function inYear(m: Moment) {
    return moment(m).add(365, 'days')
}
