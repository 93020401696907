import React, {Component} from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {resolveExpensePanelDesc} from "../../utils/Utils";
import {hasWarning} from "../../utils/Utils";
import {Button} from "react-bootstrap";
import {ExpensesService} from "../../service/ExpensesService";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt, faEye, faEyeSlash, faEdit} from '@fortawesome/free-regular-svg-icons'
import {Expense} from "../../api/client";
import {RouteComponentProps} from "react-router";

import {withRouter} from "react-router-dom";

type ExpensePanelProps = RouteComponentProps & {
    expense: Expense
    expensesService: ExpensesService
}

type ExpensePanelState = {
    executing: boolean,
    disabled: boolean
}

class ExpensePanel extends Component<ExpensePanelProps, ExpensePanelState> {

    readonly state = {executing: false, disabled: false}

    componentDidMount() {
        this.setState({disabled: this.props.expense.disabled!!})
    }

    render() {
        return <Card className="m-1 border-secondary">
            <Card.Body className={"p-1"}>
                <Container>
                    {this.state.disabled ?
                        <Row>
                            <Col xs={2} className="text-left text-muted ellipsised">
                                <del>{this.props.expense.name}</del>
                            </Col>
                            <Col className={"mb-0 text-muted"}><del>{resolveExpensePanelDesc(this.props.expense.type!!, this.props.expense.params)}</del></Col>
                            <Col xs={2} className={"text-right text-muted"}>
                                <del>{this.props.expense.amount}</del>
                            </Col>
                            <Col xs={3} className="text-right">
                                <Button className="py-0 pl-2 pr-1 mx-1" variant="outline-info"
                                        disabled={this.state.executing}
                                        onClick={() => this.onEditClicked(this.props.expense)}>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </Button>
                                <Button className="px-1 py-0 mx-1" variant="outline-info"
                                        disabled={this.state.executing}
                                        onClick={this.onDisableClicked}>
                                    <FontAwesomeIcon icon={faEye}/>
                                </Button>
                                <Button className="px-2 py-0 mx-1" variant="outline-danger"
                                        disabled={this.state.executing}
                                        onClick={this.onDeleteClicked}>
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </Button>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={2} className="text-left ellipsised">{this.props.expense.name}</Col>
                            <Col className={"mb-0" + (hasWarning(this.props.expense.type!!, this.props.expense.params) ? " text-danger" : " text-muted")}>
                                {resolveExpensePanelDesc(this.props.expense.type!!, this.props.expense.params)}
                            </Col>
                            <Col xs={2} className={"text-right"}>{this.props.expense.amount}</Col>
                            <Col xs={3} className="text-right">
                                <Button className="py-0 pl-2 pr-1 mx-1" variant="outline-info"
                                        disabled={this.state.executing}
                                        onClick={() => this.onEditClicked(this.props.expense)}>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </Button>
                                <Button className="px-1 py-0 mx-1" variant="outline-info"
                                        disabled={this.state.executing}
                                        onClick={this.onDisableClicked}>
                                    <FontAwesomeIcon icon={faEyeSlash}/>
                                </Button>
                                <Button className="px-2 py-0 mx-1" variant="outline-danger"
                                        disabled={this.state.executing}
                                        onClick={this.onDeleteClicked}>
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </Button>
                            </Col>
                        </Row>
                    }
                </Container>
            </Card.Body>
        </Card>
    }

    onDeleteClicked = () => {
        this.setState({executing: true})
        this.props.expensesService.deleteExpense(this.props.expense.id!!).then(r => {
            if (r.ok) {
                window.location.reload();
            } else {
                this.setState({executing: false})
            }
        }).catch(() => {
            this.setState({executing: false})
        })
    }

    onDisableClicked = () => {
        this.setState({executing: true})
        const currentFlag = this.state.disabled;
        this.props.expensesService.disableExpense(this.props.expense.id!!, !currentFlag).then(r => {
            if (r.ok) {
                this.setState({executing: false, disabled: !currentFlag})
            } else {
                this.setState({executing: false})
            }
        }).catch(() => {
            this.setState({executing: false})
        })
    }

    onEditClicked = (expense: Expense) => {
        this.props.history.push("/update-expense", {expense: expense});
    }
}

export default withRouter(ExpensePanel)
