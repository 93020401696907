import React, {Component} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {EVERY_X_DAYS, MONTHLY, ONE_TIME, OVER_MONTH} from "../../utils/Const";
import {ExpensesService} from "../../service/ExpensesService";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {Location} from "history";
import {formatMomentISO, parseMomentISO} from "../../utils/Utils";
import InputAmount from "../input-amount/InputAmount";
import {Expense} from "../../api/client";
import moment, {Moment} from "moment";

type LocationState = {
    expense: Expense
}

type CreateExpensePageProps = RouteComponentProps<{}, any, LocationState> & { type?: string, expensesService: ExpensesService }

type CreateExpensePageState = { createNew: boolean, processing: boolean, id?: number, name: string, amount: string, dayOfMonth: string, date: Moment, period: string }

class CreateExpensePage extends Component<CreateExpensePageProps, CreateExpensePageState> {
    private today = moment().startOf('day');

    initState = (state?: Location<LocationState>, type?: string | undefined) => {
        console.log(state)
        console.log(type)

        if (type != null) {
            return {createNew: true, processing: false, type: type!!, name: "", amount: "", dayOfMonth: "", date: moment(this.today), period: ""}
        }

        const existing = state?.state.expense
        if (existing == null) {
            return {createNew: true, processing: false, type: "MONTHLY", name: "", amount: "", dayOfMonth: "", date: moment(this.today), period: ""}
        }

        const existingType = existing.type
        switch (existingType) {
            case "MONTHLY":
                return {createNew: false, processing: false, id: existing.id, type: existingType, name: existing.name!!, amount: existing.amount!!.toString(), dayOfMonth: existing.params!!, date: moment(this.today), period: ""}
            case "ONE_TIME":
                return {createNew: false, processing: false, id: existing.id, type: existingType, name: existing.name!!, amount: existing.amount!!.toString(), dayOfMonth: "", date: parseMomentISO(existing.params!!), period: ""}
            case "OVER_MONTH":
                return {createNew: false, processing: false, id: existing.id, type: existingType, name: existing.name!!, amount: existing.amount!!.toString(), dayOfMonth: "", date: moment(this.today), period: ""}
            case "EVERY_X_DAYS":
                return {createNew: false, processing: false, id: existing.id, type: existingType, name: existing.name!!, amount: existing.amount!!.toString(), dayOfMonth: "", date: parseMomentISO(existing.params!!.split(";")[0]), period: existing.params!!.split(";")[1]}
        }

        return {createNew: true, processing: false, type: existingType!!, name: "123", amount: "", dayOfMonth: "", date: moment(this.today), period: ""}
    }

    readonly state = this.initState(this.props.location, this.props.type);

    render() {
        return <Container>
            <Row className="mt-3">
                <Col className="text-center"><h3>{this.state.createNew ? "Create" : "Update"} expense</h3></Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="newExpenseName">
                        <Form.Label>Expense name</Form.Label>
                        <Form.Control type="text" placeholder="Enter expense name" value={this.state.name}
                                      onChange={this.handleNameInputChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputAmount onValidInput={(v) => this.setState({amount: v})} value={this.state.amount} />
                </Col>
            </Row>
            {this.state.type === MONTHLY ?
                <Row>
                    <Col>
                        <Form.Group controlId="newExpenseMonthlyDay">
                            <Form.Label>Day of month</Form.Label>
                            <Form.Control type="text" placeholder="Enter day of month (1 - 28)"
                                          value={this.state.dayOfMonth}
                                          onChange={this.handleDayOfMonthInputChange}/>
                        </Form.Group>
                    </Col>
                </Row> : null
            }
            {this.state.type === ONE_TIME ?
                <Row>
                    <Col>
                        <Form.Group controlId="newExpenseOneTimeDate">
                            <Form.Label>Expense date</Form.Label>
                            <Form.Control type="date" placeholder="Enter expense date"
                                          value={formatMomentISO(this.state.date)}
                                          onChange={this.handleDateInputChange}/>
                        </Form.Group>
                    </Col>
                </Row> : null
            }
            {this.state.type === EVERY_X_DAYS ?
                <div><Row>
                    <Col>
                        <Form.Group controlId="newExpenseEveryXDate">
                            <Form.Label>Starting from</Form.Label>
                            <Form.Control type="date" placeholder="Enter starting date"
                                          value={formatMomentISO(this.state.date)}
                                          onChange={this.handleDateInputChange}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="newExpenseEveryXPeriod">
                            <Form.Label>Period (days)</Form.Label>
                            <Form.Control type="text" placeholder="Enter period of days (1 - 365)"
                                          value={this.state.period}
                                          onChange={this.handlePeriodInputChange}/>
                        </Form.Group>
                    </Col>
                </Row></div> : null
            }
            <Row>
                <Col className="text-center">
                    <Button disabled={this.isButtonDisabled()}
                            onClick={this.handleCreateClicked}>{this.state.createNew ? "Create" : "Update"}</Button>
                </Col>
            </Row>
        </Container>
    }

    handleCreateClicked = () => {
        this.setState({processing: true});
        const type = this.state.type;

        const name = this.state.name;
        const amount = this.state.amount;
        let params: string;
        if (type === MONTHLY) {
            params = this.state.dayOfMonth
        } else if (type === ONE_TIME) {
            params = formatMomentISO(this.state.date)
        } else if (type === EVERY_X_DAYS) {
            params = formatMomentISO(this.state.date) + ";" + this.state.period
        } else {
            params = ""
        }

        if (this.state.createNew) {
            this.props.expensesService.addExpense(type, name, amount, params)
                .then(r => {
                    if (r.ok) {
                        this.props.history.push("/");
                    } else {
                        this.setState({processing: false});
                    }
                })
                .catch((e) => {
                    this.setState({processing: false});
                    console.log(e); // LOG
                })
        } else {
            this.props.expensesService.updateExpense(this.state.id!!, name, amount, params)
                .then(r => {
                    if (r.ok) {
                        this.props.history.push("/");
                    } else {
                        this.setState({processing: false});
                    }
                })
                .catch((e) => {
                    this.setState({processing: false});
                    console.log(e); // LOG
                })
        }

    }

    handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({name: e.target.value});
    }

    handleDayOfMonthInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({dayOfMonth: ""});
            return;
        }

        let value = Number(e.target.value);
        if (isNaN(value) || !Number.isInteger(value) || value < 1 || value > 28) {
            return;
        }

        this.setState({dayOfMonth: value.toString()});
    }

    handlePeriodInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({period: ""});
            return;
        }

        let value = Number(e.target.value);
        if (isNaN(value) || !Number.isInteger(value) || value < 1 || value > 365) {
            return;
        }

        this.setState({period: value.toString()});
    }

    handleDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({date: parseMomentISO(e.target.value)});
    }

    isButtonDisabled = () => {
        if (this.state.processing) return true;

        const expenseType = this.state.type;

        const nameFilled = this.state.name !== "";
        const amountFilled = this.state.amount !== "";
        const paramFilled =
            expenseType === ONE_TIME ||
            expenseType === OVER_MONTH ||
            (expenseType === MONTHLY && this.state.dayOfMonth !== "") ||
            (expenseType === EVERY_X_DAYS && this.state.period !== "");

        return !(nameFilled && amountFilled && paramFilled);
    }
}

export default withRouter(CreateExpensePage);
