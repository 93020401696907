import React, {Component} from "react";
import {Container, Col} from "react-bootstrap";
import {Expense} from "../../api/client";
import ExpenseGroup from "../expense-group/ExpenseGroup";
import {ExpensesService} from "../../service/ExpensesService";
import {Alert} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {Row} from "react-bootstrap";
import {MONTHLY, ONE_TIME, OVER_MONTH, EVERY_X_DAYS} from "../../utils/Const";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";

type ExpensesPageProps = RouteComponentProps & { expensesService: ExpensesService }

const initialState = {error: false, expenses: new Array<Expense>()}
type State = Readonly<typeof initialState>

class ExpensesPage extends Component<ExpensesPageProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.props.expensesService.getExpenses()
            .then(expenses => this.setState({expenses: expenses, error: false}))
            .catch(e => {
                this.setState({error: true});
                console.log(e); // LOG
            })
    }

    render() {
        return this.state.error ? <Alert variant={'danger'}>An error occurred while loading expenses</Alert> :
            <Container>
                <Row className="mt-3">
                    <Col className="text-center"><h3>Expenses</h3></Col>
                </Row>
                <Row>
                    <Col>
                        <ExpenseGroup key="monthly-group" type={MONTHLY} expenses={this.state.expenses}
                                      expensesService={this.props.expensesService}/>
                        <ExpenseGroup key="every-x-group" type={EVERY_X_DAYS} expenses={this.state.expenses}
                                      expensesService={this.props.expensesService}/>
                        <ExpenseGroup key="over-month-group" type={OVER_MONTH} expenses={this.state.expenses}
                                      expensesService={this.props.expensesService}/>
                        <ExpenseGroup key="one-time-group" type={ONE_TIME} expenses={this.state.expenses}
                                      expensesService={this.props.expensesService}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" style={{marginBottom: '30px'}}>
                        <Button disabled={this.state.expenses == null || this.state.expenses.length === 0}
                                onClick={() => this.props.history.push("/report")}>Plan a period</Button>
                    </Col>
                </Row>
            </Container>
    }
}

export default withRouter(ExpensesPage)
