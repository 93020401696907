import React, {Component} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import {ExpensesServiceImpl} from "./service/ExpensesService";
import ExpensesPage from "./components/expenses-page/ExpensesPage";
import {Route} from 'react-router-dom';
import CreateExpensePage from "./components/create-expense-page/CreateExpensePage";
import {EVERY_X_DAYS, MONTHLY, ONE_TIME, OVER_MONTH} from "./utils/Const";
import ReportPage from "./components/report-page/ReportPage";
import {Alert, Container} from "react-bootstrap";

const expenseService = ExpensesServiceImpl;

type AppState = { ready: boolean, auth: boolean, error: boolean }

class App extends Component<any, AppState> {

    readonly state = {ready: false, auth: false, error: false}

    componentDidMount() {
        this.setState({ready: false})
        expenseService.auth().then(r => {
            this.setState({ready: true})
            if (r.status === 200) {
                this.setState({auth: true, error: false})
            } else if (r.status === 401) {
                this.setState({auth: false, error: false})
            } else {
                console.log("Unexpected status " + r.status) // LOG
                this.setState({auth: false, error: true})
            }
        }).catch(e => {
            console.log("Error " + e.toString()) // LOG
            this.setState({ready: true, auth: false, error: true})
        })
    }

    render() {
        if (!this.state.ready) {
            return <div className="App"><Container fluid className={"text-center"}>Loading...</Container></div>
        }
        if (this.state.error) {
            return <div className="App"><Alert variant={'danger'}>An unexpected error occurred</Alert></div>
        }
        if (!this.state.auth) {
            return <div className="App"><Alert variant={'danger'}>Not authorized</Alert></div>
        }

        return <div className="App">
            <Route exact path='/' render={
                (props) => <ExpensesPage {...props} expensesService={expenseService}/>
            }/>
            <Route exact path='/create-monthly' render={
                (props) => <CreateExpensePage {...props} type={MONTHLY} expensesService={expenseService}/>
            }/>
            <Route exact path='/create-one-time' render={
                (props) => <CreateExpensePage {...props} type={ONE_TIME} expensesService={expenseService}/>
            }/>
            <Route exact path='/create-over-month' render={
                (props) => <CreateExpensePage {...props} type={OVER_MONTH} expensesService={expenseService}/>
            }/>
            <Route exact path='/create-every-x' render={
                (props) => <CreateExpensePage {...props} type={EVERY_X_DAYS} expensesService={expenseService}/>
            }/>
            <Route exact path='/report' render={
                (props) => <ReportPage {...props} expensesService={expenseService}/>
            }/>
            <Route exact path='/update-expense' render={
                (props) => <CreateExpensePage {...props} expensesService={expenseService}/>
            }/>
        </div>
    }
}

export default App;
