import React, {Component} from "react";

import {Report} from "../../api/client";
import {Container} from "react-bootstrap";
import {Alert} from "react-bootstrap";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-regular-svg-icons'

type ReportProps = {
    report: Report
    startAmount: number
    startDate: string
    endDate: string
}

type ReportState = {
    debits: ReportDebit[]
    startAmount: number
}

type ReportDebit = {
    id: number
    name: string
    amount: number
    date: string
    enabled: boolean
}

class ReportPanel extends Component<ReportProps, ReportState> {

    readonly state = {
        debits: this.props.report.debits ? this.props.report.debits.map((d, i) => ({
            id: i,
            name: d.name,
            amount: d.amount,
            date: d.date,
            enabled: true
        } as ReportDebit)) : [],
        startAmount: this.props.startAmount
    }

    render() {
        const spent = this.state.debits.length === 0 ? 0 : this.state.debits.filter(d => d.enabled).map(d => d.amount).reduce((p, c) => p + c, 0)
        const available = this.state.startAmount - spent;
        const positive = available >= 0;

        return <Container fluid className="text-left">
            <Alert variant={positive ? "success" : "danger"}>
                <Row>
                    <Col><b>Start balance:</b> {this.props.startAmount}</Col>
                </Row>
                <Row>
                    <Col><b>Period:</b> {this.props.startDate} → {this.props.endDate}</Col>
                </Row>
                <hr/>
                {this.state.debits.map(d =>
                    <Row className={d.enabled ? "" : "text-muted"}
                         style={d.enabled ? {} : {textDecoration: 'line-through'}}>
                        <Col xs={1} onClick={() => this.debitClicked(d.id)}><FontAwesomeIcon
                            icon={d.enabled ? faCheckCircle : faTimesCircle}/></Col>
                        <Col xs={1}>{d.amount}</Col>
                        <Col xs={3}>{d.name}</Col>
                        <Col className="text-muted">
                            {d.date
                                ? 'on ' + new Date(d.date).toLocaleDateString()
                                : 'during the period'}
                        </Col>
                    </Row>
                )}

                {this.state.debits.length > 0
                    ? <b><Row>
                        <Col xs={1}>Total:</Col>
                        <Col xs={1}>{spent}</Col>
                    </Row></b>
                    : "No expenses during the given period"}
                <hr/>
                <Row className={"mt-2"}>
                    <Col>
                        <b>{positive ? "Available money by the end of the period: " : "Money overrun: "} {available}</b>
                    </Col>
                </Row>
            </Alert>
        </Container>
    }

    private debitClicked(debitId: number) {
        const debitsCloned = [...this.state.debits];
        debitsCloned.forEach(d => {
            if (d.id === debitId) d.enabled = !d.enabled
        });
        this.setState({debits: debitsCloned});
    }
}

export default ReportPanel
