import {Expense, Report} from "../api/client";

export interface ExpensesService {
    auth: () => Promise<Response>
    getExpenses: () => Promise<Expense[]>
    addExpense: (type: string, name: string, amount: string, params: string) => Promise<Response>
    updateExpense: (id: number, name: string, amount: string, params: string) => Promise<Response>
    deleteExpense: (id: number) => Promise<Response>
    disableExpense: (id: number, disabled: boolean) => Promise<Response>
    getReport: (incomeDate: string, amount: string, nextIncomeDate: string) => Promise<Report>
}

export const ExpensesServiceImpl: ExpensesService = {
    auth: () => {
        return fetch("/api/auth", {credentials: 'include'});
    },

    getExpenses: () => {
        return fetch("/api/expenses", {credentials: 'include'}).then(res => res.json())
    },

    addExpense(type: string, name: string, amount: string, params: string) {
        return fetch("/api/expenses?type=" + type + "&name=" + name + "&amount=" + amount + "&params=" + params,
            {method: "POST", mode: "cors", credentials: "include", headers: {'Content-Type': 'application/json'}})
    },

    updateExpense(id: number, name: string, amount: string, params: string) {
        return fetch("/api/expenses/update?id=" + id + "&name=" + name + "&amount=" + amount + "&params=" + params,
            {method: "POST", mode: "cors", credentials: 'include'})
    },

    deleteExpense(id: number): Promise<Response> {
        return fetch("/api/expenses?id=" + id, {method: "DELETE", mode: "cors", credentials: 'include'});
    },

    disableExpense(id: number, disabled: boolean): Promise<Response> {
        return fetch("/api/expenses?id=" + id + "&disabled=" + disabled, {method: "PUT", mode: "cors", credentials: 'include'});
    },

    getReport(incomeDate: string, amount: string, nextIncomeDate: string) {
        return fetch("/api/report?incomeDate=" + incomeDate + "&amount=" + amount + "&nextIncomeDate=" + nextIncomeDate, {credentials: 'include'})
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error(res.statusText);
            })
    }
}
